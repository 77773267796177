<template>
  <v-card
    id="recurring-order-form"
    min-height="350"
    :class="{'elevation-0': !fromVideo}"
  >
    <blocked-times-tab-general
      :data-params="generalData"
      :tab="tab"
      :option="option"
      :from-video="fromVideo"
      @changeTab="changeTab"
      @cancel="cancelAction"
      @save="saveAction"
    ></blocked-times-tab-general>
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
import {
  mdiAccountOutline,
  mdiTableArrowLeft,
} from '@mdi/js'
import {
  onMounted, reactive, ref, watch,
} from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { convertHour12To24, convertHour24To12 } from '@core/utils/reservation'
import { error, success } from '@core/utils/toasted'
import { createBlockedTime, editBlockedTime, getBlockedTime } from '@api'
import useSelectOptions from '@core/utils/useSelectOptions'
import useBlockedTimesList from './useBlockedTimesList'

// components
import BlockedTimesTabGeneral from './BlockedTimesTabGeneral.vue'

export default {
  components: {
    BlockedTimesTabGeneral,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    option: {
      type: Number,
      required: false,
      default: 2,
    },
    fromVideo: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { route, router } = useRouter()
    const { configFacility, configOrganization } = useSelectOptions()
    const {
      weekdayOptions,
      userData,
    } = useBlockedTimesList()

    // const option = ref(1)
    const tab = ref(0)
    const pageData = ref()
    const filterData = ref()
    const dataId = ref(null)
    const nowDate = ref(
      new Date(new Date()).toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const generalData = ref({
      facility_id: null,
      field_ids: [],
      start_time: null,
      end_time: null,
      status: 'A',
      weekdays: [],
      blocked_date: nowDate.value,
      blocked_end_date: null,
      make_recurring: 'N',
      frequency: null,
      type: null,
    })
    const postData = ref({
      facility_id: null,
      field_ids: [],
      start_time: null,
      end_time: null,
      status: 'A',
      weekdays: [],
      blocked_date: nowDate.value,
      blocked_end_date: null,
      make_recurring: 'N',
      frequency: null,
      type: null,
    })
    const putData = ref({
      facility_id: null,
      field_ids: [],
      start_time: null,
      end_time: null,
      status: 'A',
      weekdays: [],
      blocked_date: nowDate.value,
      blocked_end_date: null,
      make_recurring: 'N',
      frequency: null,
      type: null,
    })

    const typeOptions = reactive({
      video: 'video',
      booking: 'booking',
    })
    const tabs = ref([
      {
        title: 'General',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
      },
    ])

    const changeTab = (tabNumber, validForm) => {
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
          }
          break
        case 2:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[2].disabled = false
          }
          break
        default:
          break
      }

      tab.value = tabNumber
    }

    const cancelAction = () => {
      tab.value = 0
      generalData.value = {
        facility_id: null,
        field_ids: [],
        start_time: null,
        end_time: null,
        status: 'A',
        weekdays: [],
        blocked_date: nowDate.value,
        blocked_end_date: null,
        make_recurring: 'N',
        frequency: null,
        type: null,
      }
    }

    const saveAction = async data => {
      let resp = null
      if (props.option === 1) {
        postData.value = Object.assign(postData.value, {
          facility_id: configFacility.value,
          field_ids: data.field_ids,
          start_time: convertHour12To24(data.start_time),
          end_time: convertHour12To24(data.end_time),
          status: data.status,
          weekdays: data.weekdays,
          blocked_date: data.blocked_date,
          blocked_end_date: data.blocked_end_date,
          make_recurring: data.make_recurring,
          frequency: data.frequency,
          type: props.fromVideo ? typeOptions.video : typeOptions.booking,
        })
        resp = await createBlockedTime(postData.value)
      }

      if (props.option === 3) {
        putData.value = Object.assign(putData.value, {
          facility_id: configFacility.value,
          field_ids: data.field_ids,
          start_time: convertHour12To24(data.start_time),
          end_time: convertHour12To24(data.end_time),
          status: data.status,
          weekdays: data.weekdays,
          blocked_date: data.blocked_date,
          blocked_end_date: data.blocked_end_date,
          make_recurring: data.make_recurring,
          frequency: data.frequency,
          type: props.fromVideo ? typeOptions.video : typeOptions.booking,
        })
        resp = await editBlockedTime(putData.value, data.id)
      }

      if (resp.ok) {
        cancelAction()

        emit('refresh')
        success(resp.message)
      } else {
        error(resp.message.text)
      }
    }

    const goBack = () => {
      // name: 'views-blocked-times-list',
      router.replace({
        name: 'views-video-view',
        params: {
          pageParams: pageData.value,
          filterParams: filterData.value,
        },
      })
    }

    watch([configOrganization, configFacility], () => {
      goBack()
    })

    onMounted(async () => {
      // props.option = route.value.params.option || 2
      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams
      dataId.value = route.value.params.id

      if (dataId.value) {
        const resp = await getBlockedTime(dataId.value)
        if (resp.ok) {
          generalData.value = resp.data
          generalData.value.start_time = convertHour24To12(generalData.value.start_time)
          generalData.value.end_time = convertHour24To12(generalData.value.end_time)
          generalData.value.field_ids = [generalData.value.field_id]
        } else goBack()
      } else {
        // props.option = 1
        generalData.value.status = 'A'
      }

      if (props.option !== 1) {
        tabs.value.forEach(e => {
          e.disabled = false
        })

        tabs.value.splice(1, 1)
      }
    })

    return {
      // option,
      tab,
      tabs,
      userData,
      pageData,
      filterData,
      dataId,
      generalData,
      postData,
      putData,
      weekdayOptions,

      changeTab,
      saveAction,
      cancelAction,
      goBack,

      // i18n
      t,

      icons: {
        mdiAccountOutline,
        mdiTableArrowLeft,
      },
    }
  },
}
</script>
