<template>
  <v-card
    flat
    class="pa-3"
    :class="{'elevation-0': !fromVideo}"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation"
      >
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <label class="font-weight-medium text-body-1">{{ t('reservations.date_and_time') }}</label>
          </v-col>

          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-if="option === 2"
              v-model="startDateComputed"
              :placeholder="t('transactions.start_date')"
              :prepend-icon="icons.mdiCalendar"
              outlined
              readonly
              dense
            ></v-text-field>
            <v-menu
              v-else
              v-model="startDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDateComputed"
                  :placeholder="t('transactions.start_date')"
                  :prepend-icon="icons.mdiCalendar"
                  outlined
                  readonly
                  dense
                  :rules="[required]"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dataComputed.blocked_date"
                color="secondary"
                @input="startDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="4"
          >
            <v-autocomplete
              v-model="dataComputed.start_time"
              :label="t('transactions.start_time')"
              :items="computedMinutes"
              item-text="value"
              item-value="value"
              outlined
              dense
              :clearable="option!==2"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            />
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="4"
          >
            <v-autocomplete
              v-model="dataComputed.end_time"
              :label="t('transactions.end_time')"
              :items="computedMinutes"
              item-text="value"
              item-value="value"
              outlined
              dense
              :clearable="option!==2"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required, validateTimeOrder(dataComputed.start_time, dataComputed.end_time)]"
            />
          </v-col>

          <v-col
            cols="12"
            class="d-flex align-center"
            fluid
          >
            <v-checkbox
              v-model="dataComputed.make_recurring"
              :label="t('videos.make_recurring')"
              true-value="Y"
              false-value="N"
              color="secondary"
              class="ma-0 pa-0 text-body-1 font-weight-medium"
              hide-details
              :readonly="option===2"
            />
          </v-col>

          <v-col
            v-if="dataComputed.make_recurring === 'Y'"
            cols="12"
            sm="12"
            md="4"
          >
            <v-select
              v-model="dataComputed.frequency"
              class="mb-0"
              :label="t('billing_cycle_types.frequency')"
              :items="recurringFrequencyOptions"
              item-text="text"
              item-value="value"
              :menu-props="{ maxHeight: '400' }"
              dense
              outlined
              :clearable="option!==2"
              :append-icon="option === 2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <v-col
            v-if="dataComputed.make_recurring === 'Y'"
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="startDateComputed"
              :placeholder="t('transactions.start_date')"
              :prepend-icon="icons.mdiCalendar"
              outlined
              disabled
              readonly
              dense
            ></v-text-field>
          </v-col>

          <v-col
            v-if="dataComputed.make_recurring === 'Y'"
            cols="12"
            sm="4"
          >
            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDateComputed"
                  :placeholder="t('transactions.end_date')"
                  :prepend-icon="icons.mdiCalendar"
                  outlined
                  readonly
                  dense
                  clearable
                  :rules="[required]"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dataComputed.blocked_end_date"
                color="secondary"
                :min="dataComputed.blocked_date"
                :readonly="option===2"
                @input="endDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            v-if="fieldsCheckboxOptions.length"
            cols="12"
          >
            <custom-checkboxes
              v-model="dataComputed.field_ids"
              :selected-checkbox="dataComputed.field_ids"
              :checkbox-content="fieldsCheckboxOptions"
              :title="t('fields.field')"
              :grid-column="{ cols: '12', sm: '3' }"
              :readonly="option===2"
            />
          </v-col>
        </v-row>

        <options-buttom-block
          :tab-number="tab"
          :is-mode-save-edit="option !== 2"
          :no-save="false"
          :info-required="fromVideo"
          :no-cancel="option === 3"
          :no-arrow="true"
          :save-label="t('tooltip.create')"
          @onCancel="onCancelButton"
          @onNextTab="changeTab"
          @onSave="onSaveAction"
        >
        </options-buttom-block>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
import { mdiMenuDown } from '@mdi/js'
import { ref, computed, onMounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import useSelectOptions from '@core/utils/useSelectOptions'
import moment from 'moment'
import { sumMinutesOptions, convertHour12To24 } from '@core/utils/reservation'
import { error as toasdError } from '@/@core/utils/toasted'

// eslint-disable-next-line object-curly-newline
import { getFieldsByFacility } from '@api'

import OptionsButtomBlock from '@/views/components/options-buttom-block/OptionsButtomBlock.vue'
import CustomCheckboxes from '@core/components/custom-checkboxes/CustomCheckboxes.vue'

export default {
  components: {
    OptionsButtomBlock,
    CustomCheckboxes,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    fromVideo: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { configFacility } = useSelectOptions()
    const form = ref(null)
    const startDateMenu = ref(false)
    const endDateMenu = ref(false)
    const dataComputed = computed(() => props.dataParams)
    const nowDate = ref(
      new Date(new Date()).toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const startDateComputed = computed({
      get: () => {
        if (props.dataParams.blocked_date) return moment(props.dataParams.blocked_date).format('ddd, MMM DD, YYYY')

        return moment(nowDate.value).format('ddd, MMM DD, YYYY')
      },
      set: val => {
        if (val) dataComputed.value.blocked_date = val
        else dataComputed.value.blocked_date = nowDate.value
      },
    })
    const endDateComputed = computed({
      get: () => moment(props.dataParams.blocked_end_date || nowDate.value).format('ddd, MMM DD, YYYY'),
      set: val => {
        dataComputed.value.blocked_end_date = val || nowDate.value
      },
    })
    const computedMinutes = computed(() => sumMinutesOptions('00:00', '23:59'))
    const recurringFrequencyOptions = computed(() => [
      { text: t('billing_cycle_types.daily'), value: 'daily' },
      { text: t('billing_cycle_types.weekly'), value: 'weekly' },
      { text: t('billing_cycle_types.monthly'), value: 'monthly' },
    ])
    const fieldsCheckboxOptions = ref([])

    const onCancelButton = () => {
      form.value.reset()
      emit('cancel')
    }

    const onSaveAction = () => {
      if (dataComputed.value.field_ids && dataComputed.value.field_ids.length === 0) {
        toasdError('Select at least one field!')
      }

      if (form.value.validate() && dataComputed.value.field_ids.length) {
        emit('save', dataComputed.value)
      }
    }

    const changeTab = tab => {
      if (dataComputed.value.field_ids && dataComputed.value.field_ids.length === 0) {
        toasdError('Select at least one field!')
      }

      if (form.value.validate() && dataComputed.value.field_ids.length) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    const fetchFields = async () => {
      if (configFacility.value) {
        const fields = await getFieldsByFacility(configFacility.value)
        fieldsCheckboxOptions.value = fields.data.map(e => ({
          title: e.name,
          subtitle: e.sport ? e.sport.name : '',
          value: e.id,
          desc: null,
          checked: false,
        }))
      } else fieldsCheckboxOptions.value = []
    }

    const validateTimeOrder = (startTime, endTime) => {
      if (!startTime || !endTime) return 'Start and end time has required.'

      const startDate24 = convertHour12To24(startTime)
      const endDate24 = convertHour12To24(endTime)
      const [startHours, startMinutes] = startDate24.split(':').map(Number)
      const [endHours, endMinutes] = endDate24.split(':').map(Number)

      const start = new Date(0, 0, 0, startHours, startMinutes)
      const end = new Date(0, 0, 0, endHours, endMinutes)

      if (start < end) return true

      return 'Start time must be less than end time'
    }

    onMounted(async () => {
      setTimeout(async () => {
        await fetchFields(configFacility.value)
      }, 200)
    })

    return {
      form,
      startDateMenu,
      endDateMenu,
      startDateComputed,
      endDateComputed,
      computedMinutes,
      recurringFrequencyOptions,
      dataComputed,
      onCancelButton,
      changeTab,
      onSaveAction,
      nowDate,

      fieldsCheckboxOptions,
      fetchFields,

      // validations
      required,
      validateTimeOrder,

      // i18n
      t,

      icons: {
        mdiMenuDown,
      },
    }
  },
}
</script>
